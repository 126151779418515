import axios from "axios"
import Register from "../types/Register"
import qs from 'qs'
import loginData from "../types/Login"

/* USER REGISTRATION */

async function registerUser(registerData: Register): Promise<any> {

    const registerUser = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/register', registerData, {
        headers: {
            'Content-Type': 'application/json'
        }
    })

    return registerUser.data
}

/* USER AUTH */

async function loginUser(loginData: loginData): Promise<any> {
    let data = qs.stringify(loginData)

    const authorization = axios.post(process.env.REACT_APP_API_URL + '/api/v1/login', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })

    return authorization
}

/* PASS RECOVERY */

async function passRecovery(email: string): Promise<any> {

    let data = {
        email: email
    }

    const passRecovery = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/forgotPassword', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })

    return passRecovery
}

/* CHANGE PASS */

async function changePass(data: { token: string, password: string }): Promise<any> {


    const changePass = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/resetPassword', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })

    return changePass
}

export { registerUser, loginUser, passRecovery, changePass }