import React from 'react'
import Button from '../UI/Button'
import RegisterType from '../types/Register'
import { changePass } from '../requests/auth';
import { Link, useNavigate } from 'react-router-dom'
import banWords from '../ban_words.json'

function PasswordChange() {

    const navigate = useNavigate()

    const [password, setPassword] = React.useState<string>('')
    const [passwordConfirm, setPasswordConfirm] = React.useState<string>('')

    const [errorMessage, setErrorMessage] = React.useState<string>('')

    function addUser() {

        /* VALIDATION */

        const isValidationPassed = registerValidation();

        if (isValidationPassed === true) {

            let data: { token: string, password: string } = {
                token: window.location.href.split('/')[4],
                password: password,

            }

            const res = changePass(data)

            res.then((response) => {
                if (response.data.message === 'password updated') {
                    console.log(response)
                    navigate('/login')
                }
            })

        }
    }

    function registerValidation() {

        let isValidationPassed = true;


        if (password.length < 5) {
            isValidationPassed = false;
            setErrorMessage('Password must be at least 5 characters long')
        }


        if (password === '' && passwordConfirm === '') {
            isValidationPassed = false;
            setErrorMessage('Password and confirm password cannot be empty')
        }

        if (password !== passwordConfirm) {
            isValidationPassed = false;
            setErrorMessage('Password and confirm password must match')
        }

        return isValidationPassed;
    }

    return (
        <div className='text-center'>
            <h1 className='font-title text-[36px] text-white md:text-[48px]'>Change your password</h1>

            <div className='flex flex-col mt-[20px] w-[90%] p-8 bg-white max-w-[850px] m-auto gap-10 rounded-[8px]'>
                <p className='text-[16px] md:text-[20px]'>Please enter your new password.</p>

                <div className='gap-7 flex flex-col'>

                    <div className='sm:flex gap-8'>
                        <div className='text-left w-full mb-4 sm:mb-0'>
                            <label htmlFor="name" className='font-title text-[16px] md:text-[20px] mb-2'>Password <span className='text-red font-title'>*</span></label>
                            <input value={password} onChange={e => setPassword(e.target.value)} type="password" name='password' id='password' placeholder='Password' className='h-12 w-full border-2 border-lightGray rounded-[8px] py-[16px] px-[16px] mt-2' />
                        </div>

                        <div className='text-left w-full'>
                            <label htmlFor="confirmpassword" className='font-title text-[16px] md:text-[20px] mb-2'>Confirm Password <span className='text-red font-title'>*</span></label>
                            <input value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} type="password" name='confirmpassword' id='confirmpassword' placeholder='Password' className='h-12 w-full border-2 border-lightGray rounded-[8px] py-[16px] px-[16px] mt-2' />
                        </div>
                    </div>

                </div>

                <div>
                    {
                        errorMessage !== '' && <p className='text-red mb-4'>{errorMessage}</p>
                    }

                    <div className='w-full md:w-[350px] m-auto mb-5' onClick={e => addUser()}>
                        <Button type='red'>Change password</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordChange