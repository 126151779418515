import React, { useEffect } from 'react'
import hero from '../images/hero.png'
import Button from '../UI/Button'
import { Link, useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

function Homepage() {

  const navigate = useNavigate()

  const token = localStorage.getItem('token')

  useEffect(() => {

    if (token) {
      const decoded = jwtDecode<any>(token!)
      if (decoded.exp * 1000 > Date.now()) {
        navigate('/board')
      }
    }
  }, [])


  return (
    <div className='w-full min-h-[85vh] lg:min-h-[unset]'>
      <div className='flex justify-center'>
        <img className='sm:w-[51%] lg:w-[39%] 2xl:w-[50%]' src={hero} alt="Hero" />
      </div>

      <div className='text-center'>
        <h1 className='text-[24px] w-[80%] m-auto font-title uppercase 2xl:text-[40px] lg:text-[34px] sm:mt-4 lg:mt-2 2xl:mt-12 sm:w-full text-white mb-2 lg:mb-2 2xl:mb-6'>free to enter  –  win $3,000 cash</h1>
        <p className='text-[18px] lg:text-[18px] 2xl:text-[20px] text-white px-4'>Make your pick each week this season, last one standing wins!</p>
      </div>

      <div className='grid sm:flex sm:justify-center gap-4 mt-4 2xl:mt-8 w-[90%] m-auto sm:w-[600px]'>
        <Link to='/how-it-works' className='min-w-[50%]'>
          <div className='w-full'>
            <Button type='white'>How it Works</Button>
          </div>
        </Link>

        <Link to='/login' className='min-w-[50%]'>
          <div className='w-full'>
            <Button type='red'>Login</Button>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Homepage