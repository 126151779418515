import React from 'react'
import Button from '../UI/Button'
import { loginUser, passRecovery } from '../requests/auth'
import loginData from '../types/Login'
import { Link, useNavigate } from 'react-router-dom'


function Recovery() {

    const navigate = useNavigate()

    const [email, setEmail] = React.useState<string>('')

    const [errorMessage, setErrorMessage] = React.useState<string>('')

    function userRecovery() {

        /* VALIDATION */

        const isValidationPassed = authValidation();

        if (isValidationPassed === true) {

            const recovery = passRecovery(email)

            recovery.then(res => {

                if (res.data) {
                    setErrorMessage('Recovery email sent. Please check your inbox.')
                }
            }).catch(err => {
                console.log(err.response)
                setErrorMessage(err.response.data)
            })

        }
    }

    function authValidation() {

        let isValidationPassed = true;

        if (email === '') {
            isValidationPassed = false;
            setErrorMessage('Email cannot be empty')
        }

        return isValidationPassed;
    }

    return (
        <div className='text-center min-h-[75vh]'>
            <h1 className='font-title text-[36px] text-white md:text-[48px]'>Reset password</h1>

            <div className='flex flex-col mt-[40px] w-[90%] p-8 bg-white max-w-[850px] m-auto gap-12 rounded-[8px]'>
                <p className='text-[16px] md:text-[20px]'>Please enter your registered email to receive your reset password link. Reset link will be sent to your email address.</p>

                <div className='gap-8 flex flex-col'>

                    <div className='sm:flex gap-8'>
                        <div className='text-left w-full mb-4 sm:mb-0'>
                            <label htmlFor="email" className='font-title text-[16px] md:text-[20px] mb-2'>Email <span className='text-red font-title'>*</span></label>
                            <input value={email} onChange={e => setEmail(e.target.value)} type="email" name='email' id='email' placeholder='Email' className='h-12 w-full border-2 border-lightGray rounded-[8px] py-[16px] px-[16px] mt-2' />
                        </div>

                    </div>

                </div>

                <div>
                    {
                        errorMessage !== '' && <p className='text-red mb-4'>{errorMessage}</p>
                    }

                    <div className='w-full md:w-[310px] m-auto mb-5' onClick={e => userRecovery()}>
                        <Button type='red'>Log in</Button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Recovery