import React, { Children } from 'react'

interface ButtonProps {
  type: string,
  children: React.ReactNode
  fixed?: boolean
}

function Button({ type, fixed, children }: ButtonProps) {
  return (
    <button className={`font-title py-[16px] pt-[10px] px-[32px] rounded-[40px] line-[14px] border-2 text-[20px] sm:text-2xl w-full ${type === 'red' ? 'bg-red text-white border-red' : type === 'gold' ? 'bg-gold text-white border-gold' : 'bg-transparent text-white border-white'}`}>{children}</button>
  )
}

export default Button