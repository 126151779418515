import React, {useState, useEffect} from 'react'

function NoPick({data, text}) {

    const [pickData, setPickData] = useState(null)

    useEffect(() => {

        if (data.weeklyPick.length === 0) {
            setPickData(data.teamsPicked[data.teamsPicked.length - 1])
            console.log(data.teamsPicked[data.teamsPicked.length - 1])
        } else {
            setPickData(data.weeklyPick)
        }
        
    }, [])

    return (
        <div className='max-w-[840px] w-[90%] md:w-full m-auto pt-16'>
            <p className='uppercase font-bold text-white text-[38px] text-center'>{text}</p>

            <hr className='w-[90%] my-12 bg-white m-auto' />

            <div className='py-0 sm:py-12'>
                <h1 className='text-white font-title text-[36px] sm:text-[48px] text-center'>your latest pick</h1>

                <div className='mt-8 m-auto rounded-tl-[22px] pt-[9px] pb-[25px] rounded-br-[22px] border border-gold w-full sm:w-[350px] h-[190px] bg-gold bg-opacity-70'>
                    <img className='h-[90px] w-[90px] object-contain m-auto' src={pickData?.WikipediaLogoURL} alt="Team Logotype" />
                    <p className='font-title text-[32px] text-center text-white leading-9'>{pickData?.City + ' ' + pickData?.FullName}</p>
                </div>
            </div>
        </div>
    )
}

export default NoPick