import React from 'react'
import Button from '../UI/Button'
import RegisterType from '../types/Register'
import { registerUser } from '../requests/auth';
import { Link, useNavigate } from 'react-router-dom'
import banWords from '../ban_words.json'

function Register({ setIsUserLoggedIn }: { setIsUserLoggedIn: React.Dispatch<React.SetStateAction<boolean>> }) {
    
    const navigate = useNavigate()

    navigate('/login')

    const [name, setName] = React.useState<string>('')
    const [displayName, setDisplayName] = React.useState<string>('')

    const [email, setEmail] = React.useState<string>('')
    const [emailConfirm, setEmailConfirm] = React.useState<string>('')

    const [password, setPassword] = React.useState<string>('')
    const [passwordConfirm, setPasswordConfirm] = React.useState<string>('')

    const [errorMessage, setErrorMessage] = React.useState<string>('')

    function addUser() {

        /* VALIDATION */

        const isValidationPassed = registerValidation();

        if (isValidationPassed === true) {

            let data: RegisterType = {
                Email: email,
                Password: password,
                Fullname: name,
                Username: displayName
            }

            const register = registerUser(data)

            register.then((response) => {
                if (response.token !== undefined) {
                    console.log(response)
                    setIsUserLoggedIn(true)
                    localStorage.setItem('token', response.token)
                    navigate('/')
                }
            }).catch((error) => {
                console.log(error)
                setErrorMessage(error.response.data.message)
            })
        }
    }

    function registerValidation() {

        let isValidationPassed = true;

        if (name === '' && displayName === '') {
            isValidationPassed = false;
            setErrorMessage('Name and display name cannot be empty')
        }

        if (email === '' && emailConfirm === '') {
            isValidationPassed = false;
            setErrorMessage('Email and confirm email cannot be empty')
        }

        if (email.toLowerCase() !== emailConfirm.toLowerCase()) {
            isValidationPassed = false;
            setErrorMessage('Email and confirm email must match')
        }

        if (password.length < 5) {
            isValidationPassed = false;
            setErrorMessage('Password must be at least 5 characters long')
        }


        if (password === '' && passwordConfirm === '') {
            isValidationPassed = false;
            setErrorMessage('Password and confirm password cannot be empty')
        }

        if (password !== passwordConfirm) {
            isValidationPassed = false;
            setErrorMessage('Password and confirm password must match')
        }

        banWords.forEach((word: string) => {
            if (name.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
                isValidationPassed = false;
                setErrorMessage('Name cannot contain banned words')
            }

            if (displayName.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
                isValidationPassed = false;
                setErrorMessage('Display name cannot contain banned words')
            }

            if (email.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
                isValidationPassed = false;
                setErrorMessage('Email name cannot contain banned words')
            }
        });



        return isValidationPassed;
    }

    return (
        <div className='text-center'>
            <h1 className='font-title text-[36px] text-white md:text-[48px]'>Register</h1>

            <div className='flex flex-col mt-[20px] w-[90%] p-8 bg-white max-w-[850px] m-auto gap-10 rounded-[8px]'>
                <p className='text-[16px] md:text-[20px]'>Enter your details and use the same email address as your Bodog account to be eligible for a prize.</p>

                <div className='gap-7 flex flex-col'>

                    <div className='sm:flex gap-8'>
                        <div className='text-left w-full mb-4 sm:mb-0'>
                            <label htmlFor="name" className='font-title text-[16px] md:text-[20px] mb-2'>Name <span className='text-red font-title'>*</span></label>
                            <input value={name} onChange={e => setName(e.target.value)} type="name" name='name' id='name' placeholder='Full name' className='h-12 w-full border-2 border-lightGray rounded-[8px] py-[16px] px-[16px] mt-2' />
                        </div>

                        <div className='text-left w-full'>
                            <label htmlFor="fullname" className='font-title text-[16px] md:text-[20px] mb-2'>Display Name <span className='text-red font-title'>*</span></label>
                            <input value={displayName} onChange={e => setDisplayName(e.target.value)} type="name" name='fullname' id='fullname' placeholder='Display name' className='h-12 w-full border-2 border-lightGray rounded-[8px] py-[16px] px-[16px] mt-2' />
                        </div>
                    </div>

                    <div className='sm:flex gap-8'>
                        <div className='text-left w-full mb-4 sm:mb-0'>
                            <label htmlFor="email" className='font-title text-[16px] md:text-[20px] mb-2'>Email <span className='text-red font-title'>*</span></label>
                            <input value={email} onChange={e => setEmail(e.target.value)} type="email" name='email' id='email' placeholder='Email' className='h-12 w-full border-2 border-lightGray rounded-[8px] py-[16px] px-[16px] mt-2' />
                        </div>

                        <div className='text-left w-full'>
                            <label htmlFor="confirmemail" className='font-title text-[16px] md:text-[20px] mb-2'>Confirm email <span className='text-red font-title'>*</span></label>
                            <input value={emailConfirm} onChange={e => setEmailConfirm(e.target.value)} type="text" name='confirmemail' id='confirmemail' placeholder='Email' className='h-12 w-full border-2 border-lightGray rounded-[8px] py-[16px] px-[16px] mt-2' />
                        </div>
                    </div>

                    <div className='sm:flex gap-8'>
                        <div className='text-left w-full mb-4 sm:mb-0'>
                            <label htmlFor="name" className='font-title text-[16px] md:text-[20px] mb-2'>Password <span className='text-red font-title'>*</span></label>
                            <input value={password} onChange={e => setPassword(e.target.value)} type="password" name='password' id='password' placeholder='Password' className='h-12 w-full border-2 border-lightGray rounded-[8px] py-[16px] px-[16px] mt-2' />
                        </div>

                        <div className='text-left w-full'>
                            <label htmlFor="confirmpassword" className='font-title text-[16px] md:text-[20px] mb-2'>Confirm Password <span className='text-red font-title'>*</span></label>
                            <input value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} type="password" name='confirmpassword' id='confirmpassword' placeholder='Password' className='h-12 w-full border-2 border-lightGray rounded-[8px] py-[16px] px-[16px] mt-2' />
                        </div>
                    </div>

                </div>

                <div>
                    {
                        errorMessage !== '' && <p className='text-red mb-4'>{errorMessage}</p>
                    }

                    <div className='w-full md:w-[310px] m-auto mb-5' onClick={e => addUser()}>
                        <Button type='red'>Register</Button>
                    </div>
                    <p className='text-[14px] md:text-[16px]'>Already have an account? <Link to='/login' className='underline'>Login here</Link></p>
                </div>
            </div>
        </div>
    )
}

export default Register