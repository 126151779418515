import { Link } from 'react-router-dom'
import Button from '../UI/Button'

function Terms() {
    return (
        <div className='md:pt-12'>
            <h1 className='text-white font-title text-[36px] md:text-[48px] text-center'>Terms and Conditions</h1>

            <div className='bg-white p-8 mt-10 rounded-sm max-w-[1024px] m-auto text-[16px] md:text-[20px]'>

                {
                    /* 
                    <p>There’s nothing quite like the NFL season kick-off.</p>
                <p>We’re running a special NFL Survivor Pool with <b>$3,000 cash up for grabs!</b></p>

                <p>It’s <b>free-to-enter</b> and all you have to do is pick one winning team each week to advance, with the last person standing taking the prize. If there is more than one winner, the prize will be split evenly.</p>
                <p>You cannot pick the same team twice, so you’ll have to pick strategically if you’re to make it to the latter stages.</p>
                <p>Additionally, you can earn free spins on the <b>Gridiron Glory</b> slot when you hit the season milestones:</p>

                <div className='grid mt-4 mb-4 ml-4'>
                    <p>- <b>5 Free Spins</b> credited for reaching <b>Gameweek 5</b></p>
                    <p>- <b>10 Free Spins</b> credited for reaching <b>Gameweek 10</b></p>
                    <p>- <b>15 Free Spins</b> credited for reaching <b>Gameweek 15</b></p>
                </div>

                <p>Do you have what it takes to go all the way? There’s only one way to find out…</p>
                <p>Sign up and make your pick today!</p>

                <p className='mt-8'>
                    <b>How to play</b>
                </p>

                <div className='grid mt-4 mb-4 ml-4'>
                    <p className='flex gap-2 mb-2'><span>1.</span><Link className='underline' to={'https://www.bodog.eu/refer?overlay=login'}>Create a Bodog account</Link> to be eligible for a prize</p>
                    <p className='flex gap-2 mb-2'><span>2.</span> Click <Link className='underline' to={'https://bit.ly/45ecvND'}>here</Link> to load the NFL Survivor Pool</p>
                    <p className='flex gap-2 mb-2'><span>3.</span> Register using the same email as your Bodog account</p>
                    <p className='flex gap-2 mb-2'><span>4.</span> Log in using the same details as above</p>
                    <p className='flex gap-2 mb-2'><span>5.</span> Select your team to win in Gameweek 1</p>
                    <p className='flex gap-2 mb-2'><span>6.</span> If your chosen team wins, be sure to check back the following week to make your selection for the next gameweek (remember you can’t select the same team twice)</p>
                    <p className='flex gap-2 mb-2'><span>7.</span> Check the <Link className='underline' to={'https://bit.ly/45ecvND'}>survivor list</Link> to see how the competition is getting on</p>
                </div>
                    */
                }

                <p className='mt-0'>
                    <b>Terms & Conditions</b>
                </p>

                <div className='grid mt-4 mb-4 ml-4'>
                    <p className='flex gap-2 mb-2'><span>1.</span> One entry per participant</p>
                    <p className='flex gap-2 mb-2'><span>2.</span> Participants must have a valid bodog.eu account</p>
                    <p className='flex gap-2 mb-2'><span>3.</span> Players must enter their email address they use for their Bodog account when submitting requests to be eligible for a prize</p>
                    <p className='flex gap-2 mb-2'><span>4.</span> Selections must be made before each gameweek has started</p>
                    <p className='flex gap-2 mb-2'><span>5.</span> Players must make it through the entire regular season to win the cash prize</p>
                    <p className='flex gap-2 mb-2'><span>6.</span> In the event of two or more people making it to the end of the regular season, the prize will be split</p>
                    <p className='flex gap-2 mb-2'><span>7.</span> Winners will be contacted via email</p>
                    <p className='flex gap-2 mb-2'><span>8.</span> Winnings will be credited within 7 days of the competition ending</p>
                    <p className='flex gap-2 mb-2'><span>9.</span> The Free Spins will be credited to your account automatically and winnings will be subject to a 40x wagering requirement. </p>
                    <p className='flex gap-2 mb-2'><span>10.</span> Any display names deemed inappropriate or offensive will be disqualified and removed from the contest</p>
                    <p className='flex gap-2 mb-2'><span>11.</span> Should Bodog detect fraud, foul play, any manipulation of the promotion rules or abuse of the promotion, the relevant player and their related accounts will be suspended from the current and any future promotions</p>
                    <p className='flex gap-2 mb-2'><span>12.</span> Bodog reserves the right to amend, suspend or cancel the promotion at any time</p>
                </div>

                <p>These terms and conditions are in addition to <Link className='underline' to={'https://www.bodog.eu/tc-general'}>Bodog’s General Terms and Conditions</Link>.</p>
            </div>

            <div className='w-[250px] m-auto mt-10'>
                <Link to='/login'><Button type='red'>Login</Button></Link>
            </div>
        </div>
    )
}

export default Terms