import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../UI/Button'
import Game from '../types/Game'
import gamesAPI from '../requests/game'
import Pick from '../Components/Pick/Pick'
import SheludeGame from '../Components/SheludeGame/SheludeGame'
import statsAPI from '../requests/stats'
import Loading from '../UI/Loading'

function Preview() {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [shelude, setShelude] = useState<Game[] | null>(null)
    const [nextWeek, setNextWeek] = useState<number>(0)

    const navigate = useNavigate()

    useEffect(() => {

        setIsLoading(true)

        const week = statsAPI.getStatistic()

        week.then((data) => {

            setNextWeek(data.currentWeek + 1)

            if (data.currentWeek + 1 === 19) {
                navigate('/leaderboard')
            } else {
                const shelude = gamesAPI.getGames(data.currentWeek + 1)

                shelude.then((data) => {
                    setShelude(data)
                    setIsLoading(false)
                })
            }


        })

    }, [])

    return (
        <div className='mt-0 md:mt-12'>
            {
                isLoading
                    ? <div className='flex justify-center pt-[200px]'><Loading w="50" h="50" /></div>
                    : <>
                        <h1 className='font-title text-[36px] md:text-[48px] text-white text-center'>Game Week {nextWeek}</h1>
                        <p className='text-center text-white text-[16px] w-[90%] md:w-[768px] m-auto mt-4 mb-4'>Here’s a preview of the next game week. You’ll be able to make your pick after the conclusion of the current game week.</p>

                        <div className='grid grid-cols-1 lg:grid-cols-2 mt-8 max-w-[800px] m-auto'>
                            {
                                shelude !== null
                                    ? shelude.map((game) => (
                                        <SheludeGame game={game} />
                                    ))
                                    : 'Loading...'
                            }
                        </div>

                        <div className='max-w-[300px] m-auto'>
                            <Link to='/leaderboard'><Button type='red'>Leaderboard</Button></Link>
                        </div>
                    </>
            }
        </div>
    )
}

export default Preview