import React from 'react'
import Pick from '../Pick/Pick'
import Game from '../../types/Game'
import gamesAPI from '../../requests/game'
import Button from '../../UI/Button'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import SelectedTeam from '../../types/SelectedTeam'
import Loading from '../../UI/Loading'
import moment from 'moment'
import NoPick from '../NoPick'

interface Props {
    setSelectedTeamData: React.Dispatch<React.SetStateAction<SelectedTeam | null>>
}

interface WeekUserData {
    currentWeek: number,
    isAlive: boolean,
    pickAvailable: boolean
}

function WeekPick({ setSelectedTeamData }: Props) {

    const [shelude, setShelude] = useState<Game[] | null>(null)
    const [selectedTeamID, setSelectedTeamID] = useState<number>(0)
    const [selectedMatchID, setSelectedMatchID] = useState<number>(0)
    const [userPicks, setUserPicks] = useState<any>(null)
    const [userWeekData, setUserWeekData] = useState<WeekUserData | null>(null)
    const [isPickedThisWeek, setIsPickedThisWeek] = useState<boolean>(false)

    const [availablePickTime, setAvailablePickTime] = useState<string | null>(null)
    const [isPickAvailable, setIsPickAvailable] = useState<boolean>(true)

    const [isUserAlreadyPicked, setIsUserAlreadyPicked] = useState<boolean>(true)

    const navigate = useNavigate()

    useEffect(() => {

        const userPicks = gamesAPI.getUserPicks()

        userPicks.then((data) => {

            if (!data.weeklyPick) {
                data.weeklyPick = []
            }

            if (!data.teamsPicked) {
                data.teamsPicked = []
            }

            setUserPicks(data)
            setUserWeekData({
                currentWeek: data.currentWeek,
                isAlive: data.IsUserAlive,
                pickAvailable: data.pickAvailable
            })

        }).catch((err) => {
            console.log(err.response.data)

            if (err.response.status === 404) {
                setUserWeekData(null)
            }
        })

    }, [])

    useEffect(() => {
        if (userWeekData?.currentWeek !== undefined) {
            const shelude = gamesAPI.getGames(userWeekData.currentWeek)

            shelude.then((data) => {
                setShelude(data)

                /* FINDING FIRST MATCH TIME (moment.js) */

                const firstMatch = data.reduce((minMatch: any, currentMatch: Game) => {
                    const currentDateTime = moment(currentMatch.DateTime);
                    const minDateTime = minMatch ? moment(minMatch.DateTime) : null;

                    if (!minDateTime || currentDateTime.isBefore(minDateTime)) {
                        return currentMatch;
                    } else {
                        return minMatch;
                    }
                }, null);

                setAvailablePickTime(firstMatch.DateTime)
            })
        }
    }, [userWeekData])


    function createPick() {

        const pick = gamesAPI.createPick(selectedMatchID, selectedTeamID)

        pick.then((result) => {

            if (result.status === 200) {
                navigate('/board/pick')
            }

        }).catch((err) => {
            console.log(err)
        })

    }

    useEffect(() => {

        if (availablePickTime !== null) {
            const now = moment()
            const pickAvailableTime = moment(shelude !== null ? shelude[0].DateTime : null).add(1, 'hours')

            if (now.isAfter(pickAvailableTime)) {
                setIsPickAvailable(false)
            } else {
                setIsPickAvailable(true)
            }
        }

    }, [availablePickTime])

    useEffect(() => {

        if (shelude !== null && userPicks !== null) {
            setIsPickedThisWeek(true)
            let matchID = shelude?.find(e => e.HomeTeamID === userPicks?.weeklyPick.TeamID || e.AwayTeamID === userPicks.weeklyPick.TeamID)?.MatchID
            setSelectedTeamID(userPicks.weeklyPick.TeamID)
            setSelectedMatchID(Number(matchID))

            setSelectedTeamData({
                teamId: userPicks.weeklyPick.TeamID,
                teamName: userPicks.weeklyPick.FullName,
                teamLogo: userPicks.weeklyPick.WikipediaLogoURL,
                teamCity: userPicks.weeklyPick.City,
            })
        }

    }, [shelude, userPicks])

    useEffect(() => {
        if (userWeekData !== null) {
            if (userWeekData.pickAvailable === false) {
                setIsUserAlreadyPicked(false)
            } else {
                setIsUserAlreadyPicked(true)
            }
        }
    }, [userWeekData])

    const [cantMakePick, setCantMakePick] = useState<boolean>(false)

    useEffect(() => {
        if (userWeekData !== null) {
            if (userWeekData.isAlive === false) {
                setCantMakePick(true)
            } else {
                setCantMakePick(false)
            }
        }
    }, [userWeekData])



    return (

        userWeekData?.isAlive === false
            ? <NoPick data={userPicks} text={'You are deceased and can no longer make picks'} />
            : isPickAvailable === false || userWeekData?.pickAvailable === false
                ? <NoPick data={userPicks} text={'You can no longer make a pick this week. Please come back next game week'} />
                : <div className='md:mt-12'>
                    <h1 className='font-title text-[36px] md:text-[48px] text-white text-center'>Game Week {userWeekData?.currentWeek}</h1>
                    <div className='flex items-center gap-4 justify-center mt-5'>
                        <p className='font-title text-[20px] md:text-[24px] uppercase text-white'>YOUR STATUS:</p>
                        {
                            userWeekData?.isAlive === true || userWeekData === null
                                ? <span className='rounded-[20px] py-2 pt-0 px-6 bg-gold uppercase text-white font-title text-[20px] md:text-[24px]'>Active</span>
                                : <span className='rounded-[20px] py-2 pt-0 px-6 bg-red uppercase text-white font-title text-[20px] md:text-[24px]'>deceased</span>
                        }

                    </div>
                    <p className='uppercase text-gold mt-8 font-title text-[24px] md:text-[40px] text-center'>make your pick</p>

                    {
                        shelude !== null
                            ?
                            <div className='grid grid-cols-1 lg:grid-cols-2 mt-8 max-w-[800px] m-auto'>
                                {
                                    shelude.map((game) => (
                                        <Pick game={game} selectedTeamID={selectedTeamID} setSelectedTeamID={setSelectedTeamID} setSelectedMatchID={setSelectedMatchID} setSelectedTeamData={setSelectedTeamData}
                                            userPicks={userPicks} availablePickTime={availablePickTime}
                                        />
                                    ))
                                }
                            </div>
                            : <div className='flex justify-center w-full pt-12'><Loading w='50px' h='50px' /></div>
                    }

                    <div className='max-w-[300px] m-auto' onClick={e => {

                        if (selectedTeamID === 0 || selectedMatchID === 0) {
                            alert('Please select a team!')
                        } else {
                            createPick()
                        }

                    }}>
                        <Button type='red'>MAKE YOUR PICK</Button>
                    </div>



                </div>

    )
}

export default WeekPick