import React, { useEffect } from 'react'
import Button from '../../UI/Button'
import { Link, useNavigate } from 'react-router-dom'
import SelectedTeam from '../../types/SelectedTeam'

interface Props {
    selectedTeamData: SelectedTeam | null
}

function PickData({ selectedTeamData }: Props) {

    const navigate = useNavigate()

    useEffect(() => {

        if (selectedTeamData === null || selectedTeamData === undefined) {
            navigate('/board')
        }
        
    }, [])

    return (
        <div className='py-0 sm:py-12'>
            <h1 className='text-white font-title text-[36px] sm:text-[48px] text-center'>you picked</h1>

            <div className='mt-8 m-auto rounded-tl-[22px] pt-[9px] pb-[25px] rounded-br-[22px] border border-gold w-[350px] h-[190px] bg-gold bg-opacity-70'>
                <img className='h-[90px] w-[90px] object-contain m-auto' src={selectedTeamData?.teamLogo} alt="Team Logotype" />
                <p className='font-title text-[32px] text-center text-white leading-9'>{selectedTeamData?.teamCity + ' ' + selectedTeamData?.teamName}</p>
            </div>

            <p className='text-white font-title text-[24px] sm:text-[36px] text-center mt-6 mb-2 sm:mt-12 sm:mb-4'>good luck!</p>
            <p className='text-[16px] sm:text-[20px] text-white text-center'>Come back after the conclusion of this game week to pick for the next one, if you’re still standing!</p>

            <div className='flex-col md:flex-row flex items-center justify-center mt-4 md:mt-8 gap-4 md:gap-8'>
                <div className='w-full md:w-[350px]'>
                    <Link to={'/leaderboard'}><Button type='gold'>view leaderboard</Button></Link>
                </div>

                <div className='w-full md:w-[350px]'>
                    <Link to='https://bit.ly/3kQLMVf'><Button type='red'>Bet on the nfl</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default PickData