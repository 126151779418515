import React from 'react'
import { Link } from 'react-router-dom'
import logotype from '../../images/Logotype.svg'
import Button from '../../UI/Button'


interface MenuProps {
  isUserLoggedIn: boolean
  setIsUserLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}

function Menu({ isUserLoggedIn, setIsUserLoggedIn }: MenuProps) {
  return (
    <div className='max-w-5xl m-auto w-full'>
      <nav className='flex justify-between p-3 2xl:p-5 items-center'>
        <Link to={isUserLoggedIn ? '/board' : '/'}><img src={logotype} alt="Logotype" /></Link>

        <div>
          <Link to={isUserLoggedIn ? 'https://bit.ly/3kQLMVf' : '/login'}>
            {
              isUserLoggedIn
                ? <Button type='red'>bet now</Button>
                : <Button type='white'>Log In</Button>
            }
          </Link>
        </div>
      </nav>
    </div>
  )
}

export default Menu