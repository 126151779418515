import React, { useEffect, useState } from 'react'
import Button from '../UI/Button'
import { Link } from 'react-router-dom'
import statsAPI from '../requests/stats'
import Team from '../types/team'
import LeaderboardUser from '../types/LeaderboardUser'

function LeaderboardFull() {

    const [survivorsLeft, setSurvivorsLeft] = useState<number>(0)
    const [mostPickedTeam, setMostPickedTeam] = useState<Team | null>(null)
    const [leaderboardData, setLeaderboardData] = useState<LeaderboardUser[]>([])

    useEffect(() => {

        /* GET STATS */

        const res = statsAPI.getStatistic()

        res.then((data) => {
            setSurvivorsLeft(data.survivors)
            setMostPickedTeam(data.team)
        })

        /* GET LEADERBOARD DATA */

        const leaderboardData = statsAPI.getLeaderboardFull()

        leaderboardData.then((data: LeaderboardUser[]) => {

            let filteredData = data.filter((user) => user.Streak !== 0)

            setLeaderboardData(filteredData)
        })


    }, [])

    return (
        <div className='pt-0 md:pt-12'>
            <h1 className='font-title text-[36px] md:text-[48px] text-white text-center'>leaderboard</h1>
            <h2 className='text-white text-[20px] md:text-[24px] font-title text-center mt-2'>survivors left: {survivorsLeft}</h2>

            <div className='max-w-[768px] w-full m-auto mt-10'>
                <div className='flex justify-between px-4 md:px-8 py-4 bg-[#DEDEDE] rounded-t-md'>
                    <p className='font-title text-black text-[16px] md:text-[20px]'>Username</p>
                    <p className='font-title text-black text-[16px] md:text-[20px]'>Most recent picks</p>
                </div>

                {
                    leaderboardData.length > 0 ?
                        leaderboardData.map((user, index) => (
                            <div className='flex justify-between px-4 md:px-8 py-4 even:bg-white odd:bg-[#DEDEDE]'>
                                <p className='font-title text-black text-[12px] md:text-[20px] truncate w-[150px] md:w-[80%]'>{user.Username}</p>

                                <div className='flex gap-6'>
                                    {
                                        user.TeamImagesUrls?.map((team) => (
                                            <div className='block text-center items-center gap-2'>
                                                <p>{team.teamName}</p>
                                                <img className='w-[40px] m-auto' src={team.logo} alt="Team Logotype" />
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        ))
                        : <p className='text-center text-black-600 p-4 bg-white'>Check back next week, leaderboard will update after Game Week 1</p>
                }
            </div>

            <div className='flex flex-col md:flex-row items-center justify-center mt-10 gap-6'>
                <div className='py-[14px] px-[20px] w-full flex justify-between md:w-[350px] items-center bg-white bg-opacity-10 rounded-tl-[10px] rounded-br-[10px] border border-zinc-100'>
                    <p className='text-white text-[20px] font-title w-[200px]'>Most picked team this week</p>
                    {
                        mostPickedTeam !== null
                            ? <img className='w-[80px]' src={mostPickedTeam?.WikipediaLogoURL} alt="Team Logotype" />
                            : null
                    }

                </div>

                <div className='w-full md:w-[400px]'>
                    <Link to='/preview'><Button type='red'>NEXT GAME WEEK</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default LeaderboardFull