import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'

function Footer() {

  /* SCROLL TO TOP */

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

    return (
        <footer className='bg-[#191818] p-4 mt-6 2xl:mt-12'>
            <div className='max-w-5xl m-auto w-full flex items-center gap-4'>
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="45" viewBox="0 0 46 45" fill="none">
                    <g clip-path="url(#clip0_221_1324)">
                        <path d="M32.6 0.0800781H0V32.1801C0 39.2601 5.74 45.0001 12.82 45.0001H45.42V12.9001C45.42 5.82008 39.68 0.0800781 32.6 0.0800781Z" fill="#E80000" />
                        <path d="M26.45 22.81H20.13C16.25 22.81 14.02 25.07 14.02 27.81V28.11C14.02 30.64 16.08 32.72 19.01 32.72H26.45C29.35 32.72 31.45 30.64 31.45 28.11V27.46C31.45 24.9 29.35 22.8 26.45 22.8M18.56 38.9C12.07 38.9 6.65997 34.16 6.65997 28.11V7.54998C6.65997 6.45998 7.64997 5.72998 8.63997 5.72998C11.41 5.72998 13.97 7.85998 13.97 10.56V18.35C15.75 17.26 17.98 16.65 19.59 16.65H26.9C33.35 16.65 38.76 21.43 38.76 27.48V28.13C38.76 34.19 33.35 38.92 26.9 38.92H18.55L18.56 38.9Z" fill="#F4F4F4" />
                    </g>
                    <defs>
                        <clipPath id="clip0_221_1324">
                            <rect width="45.41" height="44.92" fill="white" transform="translate(0 0.0800781)" />
                        </clipPath>
                    </defs>
                </svg>

                <div>
                    <Link className='text-white underline' target='_blank' to='/terms'>Terms and Conditions apply</Link>
                    <p className='text-white'>Bodog is a registered trademark. All rights reserved</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer