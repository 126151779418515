import PickData from '../Components/PickData/PickData'
import WeekPick from '../Components/WeekPick/WeekPick'
import { useEffect, useState } from 'react'
import SelectedTeam from '../types/SelectedTeam'
import { Routes, Route } from 'react-router-dom'

function Board() {

    const [selectedTeamData, setSelectedTeamData] = useState<SelectedTeam | null>(null)

    return (
        <Routes>
            <Route path="/" element={<WeekPick setSelectedTeamData={setSelectedTeamData} />} />
            <Route path="/pick" element={<PickData selectedTeamData={selectedTeamData} />} />
        </Routes>
    )
}

export default Board