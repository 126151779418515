import React, { useEffect, useState } from 'react'
import Game from '../../types/Game'
import SelectedTeam from '../../types/SelectedTeam'
import moment from 'moment'

interface Props {
    game: Game,
    selectedTeamID: number,
    userPicks: any,
    availablePickTime: string | null,
    setSelectedTeamID: React.Dispatch<React.SetStateAction<number>>
    setSelectedMatchID: React.Dispatch<React.SetStateAction<number>>
    setSelectedTeamData: React.Dispatch<React.SetStateAction<SelectedTeam | null>>
}

function Pick({ game, selectedTeamID, userPicks, availablePickTime, setSelectedTeamID, setSelectedMatchID, setSelectedTeamData }: Props) {

    const selectedStyle = 'border-gold bg-opacity-100 bg-[#D7AA50]'
    const unpickableStyle = 'cursor-not-allowed opacity-50 pointer-events-none'
    const peekUnvaliable = 'pointer-events-none'

    function selectTeam(winner: string) {

        setSelectedTeamID(winner === 'home' ? game.HomeTeamID : game.AwayTeamID)
        setSelectedMatchID(game.MatchID)

        let data: SelectedTeam = {
            teamId: winner === 'home' ? game.HomeTeamID : game.AwayTeamID,
            teamName: winner === 'home' ? game.HomeTeamName : game.AwayTeamName,
            teamLogo: winner === 'home' ? game.HomeTeamLogo : game.AwayTeamLogo,
            teamCity: winner === 'home' ? game.HomeTeamCity : game.AwayTeamCity,
        }

        setSelectedTeamData(data)

    }

    const [isPickAvailable, setIsPickAvailable] = useState<boolean>(true)

    useEffect(() => {

        
        if (availablePickTime !== null) {

            const now = moment()
            const pickAvailableTime = moment(game.DateTime)

            if (now.isAfter(pickAvailableTime)) {
                setIsPickAvailable(false)
            } else {
                setIsPickAvailable(true)
            }

        }


    }, [isPickAvailable])

    return (
        <div className={`flex items-center gap-3 mb-8 justify-center ${isPickAvailable === false ? unpickableStyle : ''} ${userPicks.IsUserAlive === false ? peekUnvaliable : ''}`}>
            <div onClick={e => {
                selectTeam('home')
            }} className={`cursor-pointer w-[165px] h-[91px] px-1.5 pt-2 pb-2 bg-opacity-10 rounded-tl-[10px] rounded-br-[10px] border border-zinc-100 flex-col justify-start items-center gap-1 inline-flex hover:bg-[#D7AA50] hover:border-gold hover:bg-opacity-70 
            ${selectedTeamID === game.HomeTeamID || userPicks?.weeklyPick.TeamID === game.HomeTeamID ? selectedStyle : 'bg-white'}
            ${userPicks?.teamsPicked.find((e: { TeamID: number }) => e.TeamID === game.HomeTeamID) !== undefined ? unpickableStyle : ''}`}>
                <div className="w-10 h-10 relative">
                    <img className="w-10 h-full object-contain left-0 top-0 absolute" src={game.HomeTeamLogo} />
                </div>
                <div className="self-stretch text-center text-zinc-100 text-sm font-title uppercase leading-[114%] tracking-wide">{game.HomeTeamCity + ' ' + game.HomeTeamName}</div>
            </div>

            <p className='font-title text-[20px] text-white'>@</p>

            <div onClick={e => {
                selectTeam('away')
            }} className={`cursor-pointer w-[165px] h-[91px] px-1.5 pt-2 pb-2 bg-opacity-10 rounded-tl-[10px] rounded-br-[10px] border border-zinc-100 flex-col justify-start items-center gap-1 inline-flex hover:bg-[#D7AA50] hover:border-gold hover:bg-opacity-70 
            ${selectedTeamID === game.AwayTeamID || userPicks?.weeklyPick.TeamID === game.AwayTeamID ? selectedStyle : 'bg-white'}
            ${userPicks?.teamsPicked.find((e: { TeamID: number }) => e.TeamID === game.AwayTeamID) !== undefined ? unpickableStyle : ''}
            `}>
                <div className="w-10 h-10 relative">
                    <img className="w-10 h-full object-contain left-0 top-0 absolute" src={game.AwayTeamLogo} />
                </div>
                <div className="self-stretch text-center text-zinc-100 text-sm font-title uppercase leading-[114%] tracking-wide">{game.AwayTeamCity + ' ' + game.AwayTeamName}</div>
            </div>

        </div>
    )
}

export default Pick