import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../UI/Button'

function HowItWorks() {
    return (
        <div className='md:pt-12'>
            <h1 className='text-white font-title text-[36px] md:text-[48px] text-center'>how it works</h1>

            <div className='bg-white p-8 mt-10 rounded-sm max-w-[768px] m-auto'>
                <p className='font-bold text-black text-[16px] md:text-[24px] text-center'>Enter for free and correctly pick one team to win each week of the season for your chance to win $3,000 cash.</p>
                <p className='text-[16px] md:text-[20px] mt-6 flex item-baseline gap-3'><span className='font-bold font-title relative bottom-[1px]'>1.</span> <p>Register using the same email as your Bodog account to be eligible for the prize</p></p>
                <p className='text-[16px] md:text-[20px] mt-6 flex item-baseline gap-3'><span className='font-bold font-title relative bottom-[1px]'>2.</span> <p>Pick a team to win for the first game week. If the team loses you are eliminated. If you pick correctly, you survive to make a pick the following week.</p></p>
                <p className='text-[16px] md:text-[20px] mt-6 flex item-baseline gap-3'><span className='font-bold font-title relative bottom-[1px]'>3.</span> <p>As is the case in most survivor pools, you cannot pick a team twice during the season. Once you “use” your pick on a team, you cannot use them for the remainder of the time you are in the survivor pool.</p></p>
            </div>

            <div className='w-[250px] m-auto mt-10'>
                <Link to='/login'><Button type='red'>Register</Button></Link>
            </div>
        </div>
    )
}

export default HowItWorks