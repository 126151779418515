import axios from 'axios'
import Statistic from '../types/statistic'
import LeaderboardUser from '../types/LeaderboardUser'


/* GET HOMEPAGE STATS */


async function getStatistic(): Promise<Statistic> {

    const res = await axios.get(process.env.REACT_APP_API_URL + `/api/v1/getStatistic`, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + window.localStorage.getItem('token')
        },
    })
    return res.data
}

/* GET LEADERBOARD */

async function getLeaderboard(): Promise<LeaderboardUser[]> {

    const res = await axios.post(process.env.REACT_APP_API_URL + `/api/v1/getUsersScoreBoard`, {}, {
        headers: {
            'Authorization': 'Bearer ' + window.localStorage.getItem('token')
        }
    })

    return res.data
}

async function getLeaderboardFull(): Promise<LeaderboardUser[]> {

    const res = await axios.post(process.env.REACT_APP_API_URL + `/api/v1/getUsersScoreBoardFull`, {}, {
        headers: {
            'Authorization': 'Bearer ' + window.localStorage.getItem('token')
        }
    })

    return res.data
}



export default { getStatistic, getLeaderboardFull }

