import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Homepage from './Pages/Homepage';
import './App.css';
import Menu from './Components/Menu/Menu';
import Footer from './Components/Footer/Footer';
import Register from './Pages/Register';
import Login from './Pages/Login';
import Board from './Pages/Board';
import AuthCheck from './hoc/AuthCheck';
import Leaderboard from './Pages/Leaderboard';
import HowItWorks from './Pages/HowItWorks';
import Preview from './Pages/Preview';
import Terms from './Pages/Terms';
import Recovery from './Pages/Recovery';
import PasswordChange from './Pages/PasswordChange';
import LeaderboardFull from './Pages/LeaderboardFull';

function App() {

  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token !== null) {
      setIsUserLoggedIn(true)
    }
  }, [])

  return (
    <div className="App">

      <BrowserRouter>
        <Menu isUserLoggedIn={isUserLoggedIn} setIsUserLoggedIn={setIsUserLoggedIn} />
        <div className='max-w-[90vw] lg:max-w-5xl m-auto w-full min-h-[calc(100vh-250px)] md:min-h-[calc(100vh-230px)]'>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/register" element={<Register setIsUserLoggedIn={setIsUserLoggedIn} />} />
            <Route path="/login" element={<Login setIsUserLoggedIn={setIsUserLoggedIn} />} />
            <Route path="/recovery" element={<Recovery />} />
            <Route path="/resetpassword/*" element={<PasswordChange />} />
            <Route path="/board/*" element={
              <AuthCheck>
                <Board />
              </AuthCheck>
            } />
            <Route path="/leaderboard" element={
              <Leaderboard />
            } />
            <Route path="/leaderboard-full" element={
              <LeaderboardFull />
            } />
            <Route path="/preview" element={
              <AuthCheck>
                <Preview />
              </AuthCheck>
            } />
          </Routes>
        </div>

        <Footer />

      </BrowserRouter>
    </div>
  );
}

export default App;
