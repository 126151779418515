import React from 'react'
import Game from '../../types/Game'

interface Props {
    game: Game,
}


function SheludeGame({ game }: Props) {
    return (
        <div className='flex items-center gap-3 mb-8 justify-center'>
            <div className={`cursor-pointer w-[165px] h-[91px] px-1.5 pt-2 pb-2 bg-white bg-opacity-10 rounded-tl-[10px] rounded-br-[10px] border border-zinc-100 flex-col justify-start items-center gap-1 inline-flex hover:bg-[#D7AA50] hover:border-gold hover:bg-opacity-70`}>
                <div className="w-10 h-10 relative">
                    <img className="w-10 h-full object-contain left-0 top-0 absolute" src={game.HomeTeamLogo} />
                </div>
                <div className="self-stretch text-center text-zinc-100 text-sm font-title uppercase leading-[114%] tracking-wide">{game.HomeTeamName}</div>
            </div>

            <p className='font-title text-[20px] text-white'>@</p>

            <div className={`cursor-pointer w-[165px] h-[91px] px-1.5 pt-2 pb-2 bg-white bg-opacity-10 rounded-tl-[10px] rounded-br-[10px] border border-zinc-100 flex-col justify-start items-center gap-1 inline-flex hover:bg-[#D7AA50] hover:border-gold hover:bg-opacity-70`}>
                <div className="w-10 h-10 relative">
                    <img className="w-10 h-full object-contain left-0 top-0 absolute" src={game.AwayTeamLogo} />
                </div>
                <div className="self-stretch text-center text-zinc-100 text-sm font-title uppercase leading-[114%] tracking-wide">{game.AwayTeamName}</div>
            </div>

        </div>
    )
}

export default SheludeGame